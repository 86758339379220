var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UserAuthService } from '@basic/owin-auth';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@basic/owin-auth";
var LocalUserAuthService = /** @class */ (function (_super) {
    __extends(LocalUserAuthService, _super);
    function LocalUserAuthService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LocalUserAuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalUserAuthService_Factory() { return new LocalUserAuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.OauthContextService)); }, token: LocalUserAuthService, providedIn: "root" });
    return LocalUserAuthService;
}(UserAuthService));
export { LocalUserAuthService };
