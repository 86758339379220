import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OauthContextService, LoginService, UserAuthService, AuthErrorInterceptor, JwtInterceptor, SiteIdParamsimplements } from '@basic/owin-auth';
import { environment } from '../environments/environment';
import { LocalLoginService } from './services/local-login.service';
import { LocalUserAuthService } from './services/local-user-auth.service';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule, MatMenuModule, MatIconModule } from '@angular/material';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    BrowserModule,
    MatSnackBarModule,
    MatMenuModule,
    MatIconModule,
    AppRoutingModule
  ],
  providers: [
    { provide: LoginService, useClass: LocalLoginService },
    { provide: UserAuthService, useClass: LocalUserAuthService },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SiteIdParamsimplements, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private _ctxService: OauthContextService) {
    this._ctxService.init(environment.siteId, environment.localUrl, environment.apiUrl, environment.tokenPrefix, environment.type.toString());
  }
}
