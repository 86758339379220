import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizationModule, AuthGuard, UiNotFoundComponent, UiNotFoundModule } from '@basic/owin-auth';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'cv/upload' },
  { path: 'cv', loadChildren: () => import('./routes/cv.routing').then(mod => mod.CvRouting), canLoad: [AuthGuard] },
  { path: '**', component: UiNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), AuthorizationModule, UiNotFoundModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
