import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { User } from './models/user.model';
import { UserAuthService } from '@basic/owin-auth';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'basicguy';
  blobResourcesUrl: String;
  hideHeader: boolean;
  accountInfo: User;

  constructor(private _router: Router, private _authService: UserAuthService<User>, private _route: ActivatedRoute) {
    this.blobResourcesUrl = environment.blobResourcesUrl;
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.hideHeader = this._router.url === '/login';
    });
    this._authService.userObservable.subscribe(user => {
      this.accountInfo = user;
    });
  }

  logout() {
    this._authService.logout();
    this._router.navigate(['/login']);
  }
}
