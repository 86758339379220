import { Injectable } from '@angular/core';
import { LoginService, BasicHttpClientService, OauthContextService, LoaderService } from '@basic/owin-auth';
import { User } from '../models/user.model';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { LocalUserAuthService } from './local-user-auth.service';

@Injectable({ providedIn: 'root' })
export class LocalLoginService extends LoginService<User>  {

}
