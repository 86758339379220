import { EnvironmentType } from './environment-type.enum';

export const environment = {
  type: EnvironmentType.develop,
  basicPlatformCore: 'http://accounts.dev.basic.net',
  apiUrl: 'http://basicguys.dev.basic.net/api',
  localUrl: 'http://basicguys.dev.basic.net',
  blobResourcesUrl: 'http://basicplatformblobdev.blob.core.windows.net/resources',
  tokenPrefix: 'basicguy',
  port: '',
  siteId: 1037
};
