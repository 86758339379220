import { Routes } from '@angular/router';
import { AuthGuard, UiNotFoundComponent } from '@basic/owin-auth';
var ɵ0 = function () { return import("./routes/cv.routing.ngfactory").then(function (mod) { return mod.CvRoutingNgFactory; }); };
var routes = [
    { path: '', pathMatch: 'full', redirectTo: 'cv/upload' },
    { path: 'cv', loadChildren: ɵ0, canLoad: [AuthGuard] },
    { path: '**', component: UiNotFoundComponent }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
