<header class="mini-header margin-bottom-small"
        *ngIf="!hideHeader">
   <div class="toolbar ">
      <div class="logo"
           [routerLink]="['/']">
         <img src="{{blobResourcesUrl}}/LOGOS/BASICNET.PNG"
              alt="BasicNet"
              class="logo-header" />
      </div>
      <ul class="right toolbar-actions">
         <li class="drop-button link "
             *ngIf="accountInfo && accountInfo.isAuthenticated">
            <a [matMenuTriggerFor]="personMenu">
               <span>
                  <mat-icon class="inline">person</mat-icon><span>Account</span>
               </span>
            </a>
            <mat-menu #personMenu="matMenu"
                      class="max-width-menu"
                      backdropClass="max-width"
                      yPosition="below">
               <div class="user-info">
                  <p>{{accountInfo.firstName}} {{accountInfo.lastName}}
                     <br>
                     <small>{{accountInfo.email}}</small>
                  </p>
               </div>
               <div class="divider"></div>
               <div class="dropdown">
                  <div [routerLink]="[]"
                       class="item"
                       (click)="logout()">
                     Logout
                  </div>
               </div>
            </mat-menu>
         </li>
      </ul>
   </div>
</header>
<div class="container">
   <router-outlet></router-outlet>
</div>