var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { LoginService } from '@basic/owin-auth';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@basic/owin-auth";
import * as i3 from "@angular/material/snack-bar";
var LocalLoginService = /** @class */ (function (_super) {
    __extends(LocalLoginService, _super);
    function LocalLoginService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LocalLoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalLoginService_Factory() { return new LocalLoginService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i2.BasicHttpClientService), i0.ɵɵinject(i2.OauthContextService), i0.ɵɵinject(i2.LoaderService), i0.ɵɵinject(i3.MatSnackBar), i0.ɵɵinject(i2.UserAuthService)); }, token: LocalLoginService, providedIn: "root" });
    return LocalLoginService;
}(LoginService));
export { LocalLoginService };
